<template>
  <section class="space-y-10">
    <QuickInfo />
    <asom-alert
      class="mb-4"
      v-if="error"
      variant="error"
      :error-message="error"
    />
    <article v-if="!isLoading">
      <h3 class="subheader">
        Current AFC Balance
      </h3>
      <br />
      <asom-card>
        <asom-grid :sm="1" :md="2" class="overflow-y-auto">
          <asom-form-field :label="'Cash Bags'" class="">
            <div class="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <caption class="hidden"></caption>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Certis Bags
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      TE Bags
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200 text-sm">
                  <template v-for="(bagPair, index) in bagPairList" :key="index">
                    <tr>
                      <td class="px-6 py-4 whitespace-nowrap w-1/2">
                        <span>{{ bagPair.certisBagNo }}</span>
                        <asom-badge v-if="bagPair.isCertisBagMoveOut" class="ml-2">
                          In Transit
                        </asom-badge>
                        <asom-badge
                          v-if="bagPair.isCertisBagInSafe"
                          class="ml-2"
                          variant="secondary"
                        >
                          In safe
                        </asom-badge>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap w-1/2">
                        {{ bagPair.teBagNo }}
                        <asom-badge
                          v-if="bagPair.isTeBagInSafe"
                          class="ml-2"
                          variant="secondary"
                        >
                          In safe
                        </asom-badge>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tfoot class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-lg font-medium text-gray-900 uppercase tracking-wider"
                    >
                      ${{ sumCertisBags }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-lg font-medium text-gray-900 uppercase tracking-wider"
                    >
                      ${{ sumTeBags }}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </asom-form-field>
          <div>
            <asom-form-field :label="'Coin Float Balance'" class="">
              <p class="mt-3 text-sm text-gray-500">${{ stationCashSummary.coinFloat.cashBalance }}</p>
            </asom-form-field>
            <denominations
              :label="'Current Coin Tube Balance'"
              :readonly="true"
              class="col-span-2"
              :noOf10CentTubes="stationCashSummary.coinFloat.noOf10CentTubes"
              :noOf20CentTubes="stationCashSummary.coinFloat.noOf20CentTubes"
              :noOf50CentTubes="stationCashSummary.coinFloat.noOf50CentTubes"
              :noOf1DollarTubes="stationCashSummary.coinFloat.noOf1DollarTubes"
            />
          </div>
        </asom-grid>
      </asom-card>
      <br />
    </article>
    <article>
      <h3 class="subheader">
        Delivery Information
      </h3>
      <br />
      <asom-card>
        <section class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            label="Source"
            :state="inputStates('formData.isSupplierContractor')"
            error="Source is required"
          >
            <asom-input-radio-group
              class="mt-3"
              horizontal
              v-model="formData.isSupplierContractor"
              :options="sourceOptions"
              :state="inputStates('formData.isSupplierContractor')"
            />
          </asom-form-field>
          <asom-form-field
            v-if="!formData.isSupplierContractor"
            label="Select station"
            :state="inputStates('formData.otherStationId')"
            error="Station is required"
          >
            <asom-input-select
              :objectModelValue="false"
              :state="inputStates('formData.otherStationId')"
              v-model="formData.otherStationId"
              :options="otherStationOptions"
            />
          </asom-form-field>
          <div v-else></div>
          <asom-form-field
            label="Requested Delivery Date"
            :state="inputStates('formData.requestedDeliveryDate')"
            required
            error="Requested Delivery Date is required"
          >
            <asom-input-date
              :state="inputStates('formData.requestedDeliveryDate')"
              :min-date="minDeliveryDate"
              :max-date="maxDeliveryDate"
              v-model="formData.requestedDeliveryDate"
            />
          </asom-form-field>
        </section>
      </asom-card>
      <br />
      <h3 class="subheader">
        Order Information
      </h3>
      <br />
      <asom-card>
        <section class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            class="col-span-2"
            label="Order Type"
            :state="inputStates('formData.orderType')"
            error="Order Type is required"
          >
            <asom-input-radio-group
              class="mt-3"
              horizontal
              v-model="formData.orderType"
              :options="orderTypeOptions"
              :state="inputStates('formData.orderType')"
            />
          </asom-form-field>
          <asom-form-field
            v-if="formData.orderType === 1"
            class=""
            label="Number of Cash Bags"
            :state="inputStates('formData.noOfCashBags')"
            error="Number of Cash Bags is required"
          >
            <asom-input-text
              type="number"
              appendText="bags"
              min="0"
              v-model.number="formData.noOfCashBags"
              :state="inputStates('formData.noOfCashBags')"
            />
          </asom-form-field>
          <div v-if="formData.orderType === 1"></div>
          <asom-form-field
            v-else-if="formData.orderType === 2"
            class="col-span-2"
            label="Order Amount"
          >
            <div
              class="AsomFormField__FieldContainer"
            >
              <table
                class="mt-2 w-full divide-y divide-gray-200 border border-gray-200"
              >
                <caption class="hidden"></caption>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Coin Tube Denominations
                    </th>
                    <th
                      scope="col"
                      class="pr-3 py-3 text-center font-medium text-gray-500 tracking-wider"
                    >
                      Coin Tube (50 coins) <br>
                      (10¢)
                    </th>
                    <th
                      scope="col"
                      class="pr-3 py-3 text-center font-medium text-gray-500 tracking-wider"
                    >
                      Coin Tube (50 coins) <br>
                      (20¢)
                    </th>
                    <th
                      scope="col"
                      class="pr-3 py-3 text-center font-medium text-gray-500 tracking-wider"
                    >
                      Coin Tube (40 coins) <br>
                      (50¢)
                    </th>
                    <th
                      scope="col"
                      class="pr-3 py-3 text-center font-medium text-gray-500 tracking-wider"
                    >
                      Coin Tube (40 coins) <br>
                      (1$)
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="w-full divide-y divide-gray-200 border border-gray-200"
                >
                  <tr class="divide-x divide-gray-200">
                    <td
                      class="px-3 w-48 text-sm font-medium text-gray-900 text-left"
                    >
                      No. of Coin Tubes
                    </td>
                    <td class="text-sm">
                      <input
                        type="number"
                        min="0"
                        class="text-center border-none w-full"
                        v-model="amount10Cents"
                      />
                    </td>
                    <td class="text-sm">
                      <input
                        type="number"
                        min="0"
                        class="text-center border-none w-full"
                        v-model="amount20Cents"
                      />
                    </td>
                    <td class="text-sm">
                      <input
                        type="number"
                        min="0"
                        class="text-center border-none w-full"
                        v-model="amount50Cents"
                      />
                    </td>
                    <td class="text-sm">
                      <input
                        type="number"
                        min="0"
                        class="text-center border-none w-full"
                        v-model="amount1Dollar"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </asom-form-field>
          
          <asom-form-field
            label="Remarks"
            :state="inputStates('formData.remarks')"
            required
            error="Remarks is required"
          >
            <asom-input-textarea
              v-model="formData.remarks"
              :state="inputStates('formData.remarks')"
            />
          </asom-form-field>
        </section>
        <template #footer>
          <div class="col-span-3 flex justify-between flex-1">
            <asom-button
              variant="link"
              @click="$router.push({ name: 'Cash Order Main Page' })"
              text="Cancel"
            />
            <asom-button
              text="Submit for Approval"
              @click="onSubmitClicked"
              :disabled="isSubmitting"
              :loading="isSubmitting"
            />
          </div>
        </template>
      </asom-card>
      <br />
      <br />
    </article>
    <asom-modal title="Confirmation" v-model="showConfirmModal">
      <p class="text-sm text-gray-500">
        Your cash order for today's delivery is an urgent order and the SOM's approval is required.
      </p>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="onSubmit"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          text="Confirm"
        />
        <asom-button
          @click="showConfirmModal = false"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </section>
</template>

<script>
import get from "lodash.get";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import moment from 'moment';
import { mapGetters, mapState } from "vuex";
import {
  createCashOrder,
  getCoinFloatSummary,
  getAFCCashBagSummary,
} from "@/services/cashManagement.service";
import { parseDateTimeToUtc } from "@/helpers/dateTimeHelpers";
import QuickInfo from "../_QuickInfo.vue";
import Denominations from "../_Denominations.vue";
import { multiply } from '@/helpers/numbers.js'
import Decimal from 'decimal.js';

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    QuickInfo,
    Denominations,
  },
  data() {
    return {
      showConfirmModal: false,
      isLoading: true,
      stationCashSummary: {
        coinFloat: {
          cashBalance: 0,
          noOf10CentTubes: 0,
          noOf20CentTubes: 0,
          noOf50CentTubes: 0,
          noOf1DollarTubes: 0,
        },
        afcCashBag: {
          certisBags: [],
          teBags: [],
        }
      },
      formData: {
        isSupplierContractor: true,
        otherStationId: null,
        orderType: 1,
        noOfCashBags: 0,
        count10Cents: 0,
        count20Cents: 0,
        count50Cents: 0,
        count1Dollar: 0,
        requestedDeliveryDate: null,
        remarks: "",
      },
      isSubmitting: false,
      error: null,
    };
  },
  validations() {
    let validations = {
      formData: {
        requestedDeliveryDate: { required },
        orderType: { required },
        noOfCashBags: { required },
      },
    };
    if (!this.formData.isSupplierContractor) {
      validations.formData.otherStationId = { required };
    }
    return validations;
  },
  mounted() {
    this.loadStationCashSummary();
  },
  watch: {
    'formData.isSupplierContractor': function(newValue) {
      if (!newValue) {
        this.formData.orderType = 1
      }
    }
  },
  computed: {
    ...mapState({
      certisBagAmount: store => store.cashManagement.configuration.certisBagAmount
    }),
    ...mapGetters({
      userStationId: 'selectedStation/id',
      lineId: "selectedStation/lineId",
      afcId: 'cashManagement/stationCashAccounts/afcId',
    }),
    cashBagOrderAmount() {
      return multiply(this.certisBagAmount, this.formData.noOfCashBags)
    },
    sourceOptions() {
      return [
        { value: true, label: "Cash Contractor" },
        { value: false, label: "Other Station" },
      ];
    },
    otherStationOptions() {
      var tmpStations = this.$store.getters["smrtResource/stationOptionsByLineId"](this.lineId);
      return tmpStations.filter(item => item.value != this.userStationId && item.name != "SMCR");
    },
    orderTypeOptions() {
      if (!this.formData.isSupplierContractor)
        return [
          { value: 1, label: "Manual Change" },
        ];
      
      return [
        { value: 1, label: "Manual Change" },
        { value: 2, label: "GTM/TUK Coin Float" },
      ];
    },
    amount10Cents: {
      get() {
        return this.formData.count10Cents;
      },
      set(value) {
        this.formData.count10Cents = value;
      },
    },
    amount20Cents: {
      get() {
        return this.formData.count20Cents;
      },
      set(value) {
        this.formData.count20Cents = value;
      },
    },
    amount50Cents: {
      get() {
        return this.formData.count50Cents;
      },
      set(value) {
        this.formData.count50Cents = value;
      },
    },
    amount1Dollar: {
      get() {
        return this.formData.count1Dollar;
      },
      set(value) {
        this.formData.count1Dollar = value;
      },
    },
    orderAmount() {
      if (this.formData.orderType === 1)
        return this.cashBagOrderAmount;
      else
        return (
          this.amount10Cents +
          this.amount20Cents +
          this.amount50Cents +
          this.amount1Dollar
        );
    },
    isValid() {
      return (
        (this.formData.isSupplierContractor || this.formData.otherStationId) &&
        this.orderAmount > 0 &&
        this.formData.requestedDeliveryDate
      );
    },
    isDeliveryForToday() {
      const today = new Date();
      if(!this.formData.requestedDeliveryDate) return false;
      return this.formData.requestedDeliveryDate.getDate() === today.getDate() 
          && this.formData.requestedDeliveryDate.getMonth() === today.getMonth() 
          && this.formData.requestedDeliveryDate.getFullYear() === today.getFullYear();
    },
    submissionData() {
      let result = {
        afcId: this.afcId,
        isSupplierContractor: this.formData.isSupplierContractor,
        orderType: this.formData.orderType,
        requestedDeliveryDate: parseDateTimeToUtc(
          this.formData.requestedDeliveryDate
        ),
        remarks: this.formData.remarks,
        otherStationId: null,
        noOfCashBags: 0,
        cashBagOrderAmount: 0,
        count10Cents: 0,
        count20Cents: 0,
        count50Cents: 0,
        count1Dollar: 0
      };

      result.otherStationId = result.isSupplierContractor
        ? null
        : this.formData.otherStationId;

      switch (result.orderType) {
        case 1:
          result = {
            ...result,
            noOfCashBags: this.formData.noOfCashBags,
            cashBagOrderAmount: this.cashBagOrderAmount,
          }
          break;
        case 2:
          result = {
            ...result,
            count10Cents: this.formData.count10Cents * 50,
            count20Cents: this.formData.count20Cents * 50,
            count50Cents: this.formData.count50Cents * 40,
            count1Dollar: this.formData.count1Dollar * 40,
          }
          break;
        default:
          break;
      }

      return result;
    },
    minDeliveryDate() {
      return new Date();
    },
    maxDeliveryDate() {
      return moment()
        .add(1, "weeks")
        .toDate();
    },
    
    bagPairList() {
      const result = [];
      const len =
        this.stationCashSummary.afcCashBag.certisBags.length > this.stationCashSummary.afcCashBag.teBags.length
          ? this.stationCashSummary.afcCashBag.certisBags.length
          : this.stationCashSummary.afcCashBag.teBags.length;
      for (let i = 0; i < len; i++) {
        result.push({
          certisBagNo: get(this.stationCashSummary.afcCashBag.certisBags, `${i}.bagNo`),
          isCertisBagMoveOut:
            get(this.stationCashSummary.afcCashBag.certisBags, `${i}.lastTransaction`) === "Move Out",
          teBagNo: get(this.stationCashSummary.afcCashBag.teBags, `${i}.bagNo`),
          isCertisBagInSafe: get(this.stationCashSummary.afcCashBag.certisBags, `${i}.inSafe`),
          isTeBagInSafe: get(this.stationCashSummary.afcCashBag.teBags, `${i}.inSafe`),
        });
      }
      return result;
    },
    sumCertisBags() {
      //return this.stationCashSummary.afcCashBag.certisBags.reduce((sum, bag) => sum + bag.amount, 0);
      return this.stationCashSummary.afcCashBag.certisBags.reduce((sum, bag) => sum.plus(new Decimal(bag.amount)), new Decimal(0)).toFixed(2);
    },
    sumTeBags() {
      //return this.stationCashSummary.afcCashBag.teBags.reduce((sum, bag) => sum + bag.amount, 0);
      return this.stationCashSummary.afcCashBag.teBags.reduce((sum, bag) => sum.plus(new Decimal(bag.amount)), new Decimal(0)).toFixed(2);
    },
  },
  methods: {
    loadStationCashSummary() {
      this.loadAFCCashBags();
      this.loadCoinFloat();
    },
    async loadAFCCashBags() {
      const result = await getAFCCashBagSummary(this.afcId);
      if (result.success) {
        this.stationCashSummary.afcCashBag.certisBags = get(result, "payload.certisBags", []);
        this.stationCashSummary.afcCashBag.teBags = get(result, "payload.teBags", []);
      }
      this.isLoading = false;
    },
    async loadCoinFloat() {
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const result = await getCoinFloatSummary(this.afcId);
      if (result.success) {
          this.stationCashSummary.coinFloat.cashBalance = get(result, "payload.data.cashBalance", 0);
        this.stationCashSummary.coinFloat.noOf10CentTubes = get(
          result,
          "payload.data.noOf10CentTubes",
          0
        );
        this.stationCashSummary.coinFloat.noOf20CentTubes = get(
          result,
          "payload.data.noOf20CentTubes",
          0
        );
        this.stationCashSummary.coinFloat.noOf50CentTubes = get(
          result,
          "payload.data.noOf50CentTubes",
          0
        );
        this.stationCashSummary.coinFloat.noOf1DollarTubes = get(
          result,
          "payload.data.noOf1DollarTubes",
          0
        );
      }
      this.isLoading = false;
    },
    async onSubmitClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.formData.$reset();
      this.v$.formData.$touch();
      if (this.isValid && !this.v$.formData.$invalid) {
        if(this.isDeliveryForToday) {
          this.showConfirmModal = true;
        } else {
          await this.onSubmit();
        }
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      this.error = null;
      const result = await createCashOrder(this.submissionData);
      this.isSubmitting = false;
      if (result.success) this.$router.push({ name: "Cash Order Main Page" });
      else {
        this.error = result.payload;
        this.$scrollTop();
      }
    }
  },
};
</script>
